import React from 'react';
import * as Images from '../../../assets';
import UpdatedAnimatedComponent from '../../AnimationWrapperComponent';

const RecentBlogPosts = ({ titleRef, isVisible }) => {
    return (
        <>
            <div className=" relative flex justify-center items-center px-16 py-20 max-md:px-5 " ref={titleRef}>
                <div className="absolute  bottom-0">
                    <img
                        src={Images.blog_into_bg}
                        alt="Images"
                        loading="lazy"
                        className="object-fill full-size"
                    />
                </div>
                <div className=" flex flex-col max-w-full w-[1296px] z-10 ">
                    <div
                        // className="self-center text-5xl font-medium  max-md:text-4xl"
                        className={`self-center text-5xl font-medium max-md:text-4xl ${isVisible ? 'about-us_title_animation_fade_in_right' : ''}`}
                    >
                        <span className=" leading-[58px] text-zinc-800">Recent Blog</span>{" "}
                        <span className="font-bold text-orange-500 leading-[58px]">
                            Posts
                        </span>
                    </div>
                    <div className=" mt-12 max-md:mt-10 max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0 ">
                            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full ">
                                <div className="flex flex-col grow self-stretch text-sm font-medium text-orange-500 max-md:mt-8 max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        src={Images.recentblog1}
                                        // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb27cbb0323a3f6d08614eae27adf4d31f0cdeb1814ba47b58dfeb44ebe0c993?apiKey=8e5983036f284a13866aae97819cdb73&"
                                        className="w-full aspect-[2.56] max-md:max-w-full"
                                        alt=''
                                    />
                                    <div className="mt-8 max-md:max-w-full">
                                        Olivia Rhye • 20 Jan 2024
                                    </div>
                                    <div className="flex gap-4 mt-3 text-2xl text-zinc-800 max-md:flex-wrap">
                                        <div className="flex-auto max-md:max-w-full"  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>
                                            5 Silver Sensations: Stunning Earrings for
                                            Your Summer Fun!
                                        </div>
                                        <img
                                            loading="lazy"
                                            src={Images.recent_blog_icon}
                                            // src="https://cdn.builder.io/api/v1/image/assets/TEMP/5daaa2a332a5d0b5ebfea7b9ca9fab9e1fd1affafc1a5a00d9e79c2cbbe0927a?apiKey=8e5983036f284a13866aae97819cdb73&"
                                            className="shrink-0 self-start mt-1 w-6 aspect-square"
                                            alt=''
                                        />
                                    </div>
                                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
                                        Summer is a time for adventure, relaxation, and soaking up the sun. Whether you are planning a beach getaway, a city exploration, or a weekend escape, the right accessories can elevate your look and make you feel confident and stylish.
                                    </div>
                                    <div className="flex gap-2 pr-20 mt-6 text-center  max-md:flex-wrap max-md:pr-5">
                                        <div className="justify-center px-2.5 py-0.5 bg-orange-100 rounded-2xl text-orange">
                                            Jewellery
                                        </div>
                                        <div className="justify-center px-2.5 py-0.5 text-blue-500 bg-indigo-100  rounded-2xl">
                                            Business
                                        </div>
                                        {/* <div className="justify-center px-2.5 py-0.5 text-emerald-700 bg-emerald-50 rounded-2xl">
                                            Lorem  
                                        </div>
                                        <div className="justify-center px-2.5 py-0.5 bg-white rounded-2xl">
                                            Lorem
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full  justify-between">
                                {/* <div className="flex flex-col  justify-center max-md:mt-8 max-md:max-w-full border"> */}
                                <div className="max-md:max-w-full ">
                                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                            <img
                                                loading="lazy"
                                                src={Images.recentblog2}
                                                // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a79933c60bd6fae775d7964380a27046d60485abc187cbc4bc59555c66a9e75?apiKey=8e5983036f284a13866aae97819cdb73&"
                                                className="grow w-full aspect-[1.59] max-md:mt-6"
                                                alt=''
                                            />
                                        </div>
                                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                                            <div className="flex flex-col text-sm font-medium max-md:mt-6">
                                                <div className="text-orange-500 ">
                                                    Phoenix Baker • 19 Jan 2024
                                                </div>
                                                {/* <div className="mt-3 text-lg  text-zinc-800">
                                                    Guiding Your Jewellery Business to Greater Heights: Headway Business Solutions LLP
                                                </div> */}
                                                <div className="mt-3 text-lg text-zinc-800" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>
                                                    Guiding Your Jewellery Business to Greater Heights: Headway Business Solutions LLP
                                                </div>

                                                <div className="mt-2 text-base text-zinc-600" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>
                                                    Guiding Your Jewellery Business to Greater Heights: Headway Business Solutions LLP...
                                                </div>
                                                <div className="flex gap-2 mt-6 text-center">
                                                    {/* <div className="flex flex-col justify-center text-orange-500  bg-white rounded-[360px]">
                                                        <div className="justify-center px-2.5 py-0.5 bg-white rounded-2xl">
                                                            Lorem
                                                        </div>
                                                    </div> */}
                                                    <div className="justify-center px-2.5 py-0.5 text-blue-500 bg-indigo-100  rounded-2xl">
                                                        Business
                                                    </div>
                                                    <div className=" justify-center px-2.5 py-0.5 text-emerald-700 bg-emerald-50 rounded-2xl ">
                                                        Training
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="max-md:max-w-full ">
                                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                            <img
                                                loading="lazy"
                                                src={Images.recentblog3}
                                                // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/da8388a2d11d140d534df5948b6d9049041092811838d6814a1d232f0a9e4a20?apiKey=8e5983036f284a13866aae97819cdb73&"
                                                className="grow w-full aspect-[1.59] max-md:mt-6"
                                                alt=''
                                            />
                                        </div>
                                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                                            <div className="flex flex-col text-sm font-medium max-md:mt-6">
                                                <div className="text-orange-500 ">
                                                    Lana Steiner • 18 Jan 2024
                                                </div>
                                                <div className="mt-3 text-lg  text-zinc-800"  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>
                                                    Mastering Gold & Silver Stock Management for Your Jewellery Business
                                                </div>
                                                <div className="mt-2 text-base text-zinc-600" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>
                                                    Behind the dazzling displays and happy customers of any jewellery business, lies a critical task: managing your gold and silver stock effectively.
                                                </div>
                                                <div className="flex gap-2 mt-6 text-center">
                                                    <div className="flex flex-col  justify-center text-orange-500  bg-white rounded-[360px]">
                                                        <div className="justify-center px-2.5 py-0.5 bg-white rounded-2xl">
                                                            Seminar
                                                        </div>
                                                    </div>
                                                    <div className="justify-center px-2.5 py-0.5 text-blue-500 bg-indigo-100  rounded-2xl">
                                                        Business
                                                    </div>
                                                    <div className=" justify-center px-2.5 py-0.5 text-emerald-700 bg-emerald-50 rounded-2xl">
                                                        Training
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default UpdatedAnimatedComponent(RecentBlogPosts)