import React, { useEffect, useRef, useState } from 'react';
import * as Images from '../../../assets';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import './logogslider.css'
import { fetchData } from '../../../apiGeneralFunction';
import Slider from 'react-slick';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Home3 = ({ data }) => {

    let sliderRef = useRef(null);
    const [isMediumOrSmaller, setIsMediumOrSmaller] = useState(false);


    // const [counterData, setCounterData] = useState([]);

    // const [currentIndex, setCurrentIndex] = useState(0);
    // const [visibleImages, setVisibleImages] = useState([]);

    // const [data, setData] = useState([]);
    // const [token, setToken] = useState('');

    // useEffect(() => {

    //     const tokenValue = localStorage.getItem('login api response token');
    //     const storedToken = JSON.parse(tokenValue);
    //     console.log("plan pricing fgascdgasfghdfshj", storedToken);
    //     if (storedToken) {
    //         setToken(storedToken);
    //     }
    //     console.log("token value", token)

    //     fetchData(`api/client_logo_list`, storedToken)
    //         .then(data => setData(data[0]))
    //         .catch(error => console.error('Error fetching data:', error));
    // }, []);

    // console.log("client logo list", data);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex(prevIndex => (prevIndex + 1) % images);
    //     }, 2000);

    //     return () => clearInterval(interval);
    // }, []);

    // useEffect(() => {
    //     const calculateVisibleImages = () => {
    //       const newIndex = currentIndex % images.length;
    //       console.log("newIndex----",newIndex);

    // console.log("visibleImages-----",visibleImages);
    //       let visible = [];

    //       for (let i = newIndex + 1; visible.length < 2; i++) {
    //         visible.push(images[i % images.length]);
    //       }

    //       setVisibleImages(visible);
    //     };

    //     calculateVisibleImages();
    //   }, [currentIndex]);

    //   console.log("visibleImages---123--",visibleImages);

    // useEffect(() => {
    //     fetch('your_data.json')
    //         .then(response => response.json())
    //         .then(data => setCounterData(data))
    //         .catch(error => console.error('Error fetching data:', error));
    // }, []);

    // const settings = {
    //     dots: true,
    //     autoplay: true,
    //     speed: 500,
    //     autoplaySpeed: 4500,
    //     cssEase: "linear",
    //     infinite: true,
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             }
    //         },

    //     ]
    // };

    const images = [
        // `${Images.home2_sub1}`,
        // `${Images.home2_sub2}`,
        // `${Images.home2_sub3}`,
        // `${Images.home2_sub4}`,
        // `${Images.home2_sub5}`,
        // Add more image URLs here
        `${Images.client_logo1}`,
        `${Images.client_logo2}`,
        `${Images.client_logo3}`,
        `${Images.client_logo4}`,
        `${Images.client_logo5}`,
    ];

    const [index, setIndex] = useState(0);

    // Function to handle auto play
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        }, 3000); // Change the interval as needed (e.g., 3000 for 3 seconds)
        return () => clearInterval(interval);
    }, [images.length]);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        // autoplay: true,
        speed: 1000,
        // autoplaySpeed: 0,
        // cssEase: "linear"
    };

    const companyImg = [
        {
            id: 1,
            imageUrl: `${Images.client_logo1}`,
            title: "Shriji Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 2,
            imageUrl: `${Images.client_logo2}`,
            title: "N.K.  Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 3,
            imageUrl: `${Images.home_mahavir_client_new_logo}`,
            title: "Mahaveer  Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 4,
            imageUrl: `${Images.client_logo4}`,
            title: "K. Virchand Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 5,
            imageUrl: `${Images.client_logo5}`,
            title: "CMS  Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 6,
            imageUrl: `${Images.client_logo1}`,
            title: "Shriji Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 7,
            imageUrl: `${Images.client_logo2}`,
            title: "N.K.  Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 8,
            imageUrl: `${Images.home_mahavir_client_new_logo}`,
            title: "Mahavir  Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 9,
            imageUrl: `${Images.client_logo4}`,
            title: "K. Virchand Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        },
        {
            id: 10,
            imageUrl: `${Images.client_logo5}`,
            title: "CMS  Jewellers",
            description: "At Headway Business Solutions LLP, we believe that your business goals are our business goals. Our highly experienced team of experts have the knowledge and expertise to help you overcome any obstacle standing between you and your desired success. We are committed to providing impartial and practical assistance to ensure that you make the most of every opportunity that comes your way. Whether you are an established business looking to consolidate your position in the market or a start-up looking for guidance, we are here to help."
        }
    ]

    useEffect(() => {
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const checkScreenSize = () => {
        // setIsMediumOrSmaller(window.innerWidth <= 640); 
        setIsMediumOrSmaller(window.innerWidth <= 1024); 
    };

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };


    return (
        <>
            {/* <section
                className='w-[1296px] rounded-md max-md:flex-wrap max-md:px-5 max-w-full shadow-2xl py-11  py-8 px-14 mt-6 justify-between self-center text-center max-sm:py-4 max-md:mt-0 shadow-orange-100'
            >
                <section className="flex gap-5 justify-between self-center py-11 pr-14 pl-5 mt-6 max-w-full bg-white rounded-md shadow-2xl w-[1296px] max-md:flex-wrap max-md:px-5">
                <div className=" slideshow flex gap-5 justify-between items-center max-md:flex-wrap max-md:max-w-full">
                    <img
                    loading="lazy"
                    className="self-stretch my-auto max-w-full aspect-[3.03] w-[150px]"
                    src={Images.home2_sub1}
                    alt=""
                />
                <img
                    loading="lazy"
                    className="self-stretch my-auto max-w-full aspect-[3.57] w-[133px]"
                    src={Images.home2_sub2}
                    alt=""
                />
                <img
                    loading="lazy"
                    className="self-stretch max-w-full aspect-[2.78] w-[151px]"
                    src={Images.home2_sub3}
                    alt=""
                />
                <img
                    loading="lazy"
                    className="self-stretch my-auto max-w-full aspect-[3.85] w-[143px]"
                    src={Images.home2_sub4}
                    alt=""
                />
                <img
                    loading="lazy"
                    className="my-auto aspect-[2.94] w-[87px]"
                    src={Images.home2_sub5}
                    alt=""
                />
                {data && data?.map((item, index) => (
                            <img
                                key={index}
                                src={item?.logo_image}
                                alt=''
                                alt={`Slide ${index}`}
                                className='my-auto '
                                className=' my-auto aspect-[2.94] w-[87px]'
                            className={index === currentIndex ? 'active my-auto aspect-[2.94] w-[87px]' : 'my-auto aspect-[2.94] w-[87px]'}
                            className={(index === currentIndex || index === currentIndex + 1 || index === currentIndex - 1) ? 'active my-auto aspect-[2.94] w-[87px]' : ' my-auto aspect-[2.94] w-[87px]'}
                            />
                        ))}
                
               
                    <div className="carousel">
                        {data && data?.map((item, index) => (
                            <img
                                key={index}
                                src={item?.logo_image}
                                alt=''
                                className='my-auto '
                            />
                        ))}

                    </div>
                    {data?.data?.map((item, index) => (
                        <h1>{item?.id}</h1>
                    ))}
                    <div className="carousel">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Slide ${index}`}
                                className=' my-auto aspect-[2.94] w-[87px]'
                            className={index === currentIndex ? 'active my-auto aspect-[2.94] w-[87px]' : 'my-auto aspect-[2.94] w-[87px]'}
                           className={(index === currentIndex || index === currentIndex + 1 || index === currentIndex - 1) ? 'active my-auto aspect-[2.94] w-[87px]' : ' my-auto aspect-[2.94] w-[87px]'}
                            />
                        ))}

                    </div>
                </div>
                </section>
                <div className="flex gap-5 justify-between items-center max-md:flex-wrap max-md:max-w-full">

                <Slider {...settings}>
                    {companyImg.map((item, index) => (
                        <div key={index}>
                            <div className=""
                            style={{ textAlign: '-webkit-center' }}
                            >
                                <img src={item.imageUrl} alt=""
                                    loading="lazy"
                                    className="self-stretch max-w-full aspect-[2.78] w-[151px]"
                                    className=" max-w-full aspect-[2.78] w-[151px]"
                                />
                            </div>
                        </div>
                    ))}
                </Slider>

                </div>
            </section> */}
            {/* {!isMediumOrSmaller &&

                <section
                    className='w-[1296px] rounded-md max-md:flex-wrap max-md:px-5 max-w-full shadow-2xl py-11  py-8 px-14 mt-6 justify-between self-center text-center max-sm:py-4 max-md:mt-0 shadow-orange-100'
                >
                  


                    {data && data.length > 0 ? (
                        <div className="carousel">
                            {data && data?.map((item, index) => (
                                <img
                                    key={index}
                                    src={item?.logo_image}
                                    alt=''
                                    className='my-auto'
                                />
                            ))}

                        </div>
                    ) : (
                        <div className="carousel">
                            {companyImg?.map((image, index) => (
                                <div className='flex items-center gap-3' key={index}>
                                    <img

                                        src={image?.imageUrl}
                                        alt={`Slide ${index}`}
                                        // className=' my-auto aspect-[2.94] w-[87px]'
                                        className=' w-full'
                                    />
                                    <div className='text-sm'>{image?.title}</div>
                                </div>
                            ))}

                        </div>
                    )}




                 

                </section>

            } */}

            {/* {isMediumOrSmaller &&
                <section className='w-[1296px] rounded-md max-md:flex-wrap max-md:px-5 max-w-full shadow-2xl py-11  py-8 px-14 mt-6 justify-between self-center text-center max-sm:py-4 max-md:mt-0 shadow-orange-100'>
                   <div className='w-full text-end mb-3'>
                    <div className="flex gap-3 self-end max-md:self-end text-end   w-auto justify-end ">
                        <div
                            onClick={previous}
                            className="cursor-pointer exit flex  justify-center items-center ">

                            <svg
                                className=' exitHover w-9 h-9 p-3 rounded-full bg-white hover:bg-orange-500'
                                xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    stroke="none">
                                    <path d="M3515 5100 c-44 -18 -189 -159 -1228 -1197 -952 -951 -1184 -1188 -1203 -1228 -18 -37 -24 -67 -24 -115 0 -132 -74 -50 1223 -1346 1123 -1123 1174 -1173 1230 -1193 73 -27 131 -27 204 1 48 17 77 40 174 137 144 143 163 177 164 286 0 58 -5 91 -19 120 -13 27 -333 355 -995 1018 l-976 977 977 978 c537 537 984 993 994 1012 9 19 19 67 22 106 7 110 -20 160 -166 305 -98 97 -127 119 -175 137 -71 27 -136 27 -202 2z" />
                                </g>
                            </svg>
                        </div>
                        <div
                            onClick={next}
                            id="show"
                            className="exit cursor-pointer  flex  justify-center items-center "
                        >
                            <svg
                                className=' exitHover w-9 h-9 p-3 rounded-full bg-white hover:bg-orange-500'
                                xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="" stroke="none">
                                    <path d="M1400 5098 c-44 -17 -77 -44 -171 -137 -144 -143 -163 -177 -164 -286 0 -58 5 -91 19 -120 13 -27 333 -355 995 -1018 l976 -977 -977 -978 c-760 -760 -982 -987 -997 -1022 -14 -30 -21 -67 -21 -110 0 -103 29 -153 168 -291 98 -97 127 -119 175 -137 73 -28 131 -28 204 -1 56 20 108 71 1230 1193 1297 1296 1223 1214 1223 1346 0 132 74 50 -1223 1346 -1123 1123 -1174 1173 -1230 1193 -72 26 -136 26 -207 -1z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    </div>
                    <div className=''>
                        <Slider
                            ref={slider => {
                                sliderRef = slider;
                            }}
                            {...settings}
                        >

                            {data && data?.length > 0 ? (
                                data?.map((item, index) => (
                                    <div
                                        className="px-5 "
                                        

                                        key={index}
                                    >
                                        <img src={item?.logo_image} alt=""
                                            loading="lazy"
                                            className='w-48'
                                        />
                                    </div>
                                ))
                            ) : (
                                companyImg?.map((image, index) => (
                                    <div
                                        className="px-5 "
                                       
                                        key={index}
                                    >
                                        <div className='flex items-center'>
                                        <img src={image?.imageUrl} alt=""
                                                loading="lazy"
                                                className='w-16 '
                                            />
                                            <div className='ms-5 w-fit'>{image?.title}</div>
                                            </div>
                                    </div>
                                ))
                            )}

                        </Slider>



                    </div>

                </section >
            } */}
            {/* <section
                className='w-[1296px] rounded-md max-md:px-5 shadow-2xl py-11  py-8 px-14 mt-6 justify-between self-center text-center max-sm:py-4 max-md:mt-0 shadow-orange-100'
            > */}
            {/* {!isMediumOrSmaller &&   */}
            <div 
           className="flex justify-center items-center px-16 py-8 max-md:px-5 max-sm:py-0  max-sm:px-0"
            >
                <div
                  className=" w-[1296px] max-md:px-5 max-w-full"
                 >
                    <div 
                    className='px-16 py-8 max-md:px-5  max-sm:mx-0 max-sm:px-0 shadow-xl shadow-orange-50 '
                    >
                    <Swiper
                        slidesPerView={5}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        speed={3000}
                        modules={[Autoplay, Navigation]}

                        className="mySwiper slider_marquee-wrapper"
                        grabCursor={true}
                        simulateTouch={true}
                        effect="slide"
                        // slidesPerGroup={5}
                        slidesPerGroup={Math.min(5, data?.length)}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                //spaceBetween: 10,
                                slidesPerGroup: 2,
                                autoplay: {
                                    delay: 0,
                                    disableOnInteraction: false,
                                },
                                speed: 3000,
                                loop: true
                            },
                            640: {
                                slidesPerView: 2,
                                //spaceBetween: 10,
                                slidesPerGroup: 2,
                                autoplay: {
                                    delay: 0,
                                    disableOnInteraction: false,
                                },
                                speed: 3000,
                                loop: true
                            },
                            // When window width is <= 768px
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                                slidesPerGroup: 2,
                                autoplay: {
                                    delay: 0,
                                    disableOnInteraction: false,
                                },
                                speed: 2000,
                                loop: true
                            },
                            // When window width is <= 992px
                            992: {
                                slidesPerView: 2,
                                // spaceBetween: 20,
                                slidesPerGroup: 2,
                                autoplay: {
                                    delay: 0,
                                    disableOnInteraction: false,
                                },
                                speed: 3000,
                                loop: true

                            },
                            // When window width is <= 1200px
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 25,
                                slidesPerGroup: Math.min(5, data?.length),
                                autoplay: {
                                    delay: 0,
                                    disableOnInteraction: false,
                                },
                                speed: 3000,
                                loop: true
                            }
                        }}
                    >
                        <div className="slider_marquee-swiper">

                            {/* {companyImg?.map((image, index) => (
                            <SwiperSlide>
                                <div className='flex items-center gap-3' key={index}>
                                <img

                                    src={image?.imageUrl}
                                    alt={`Slide ${index}`}
                                  
                                    className=' w-36'
                                />
                            
                                </div>
                            </SwiperSlide>
                        ))} */}
                            {data && data?.length > 0 ? (

                                data?.map((item, index) => (
                                    <SwiperSlide key={index} className='px-5'>
                                        {/* <div
                                            className="px-5 "

                                         key={index}
                                        > */}
                                            <img src={item?.logo_image} alt="Images"
                                                loading="lazy"
                                                className=' max-sm:w-52'
                                            //   className='w-48 max-md:w-96'
                                            />
                                        {/* </div> */}
                                    </SwiperSlide>
                                ))
                            ) : (
                                companyImg?.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <div
                                            className="px-5 flex items-center"


                                        >
                                            <img src={image?.imageUrl} alt="Images"
                                                loading="lazy"
                                                className='w-16 max-sm:w-10 '
                                            />
                                            <div className='ms-5 max-sm:text-sm'>{image?.title}</div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            )}
                        </div>
                        
                    </Swiper>
                    </div>
                </div>
            </div>
            {/* }  */}

            {/* </section> */}
        </>
    )
}

export default Home3